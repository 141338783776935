import type { Stream, RequestOptions } from "@dxos/codec-protobuf";
import substitutions from "../../substitutions.js";
import * as dxos_agent_dashboard from "./agent/dashboard.js";
import * as dxos_agent_echoproxy from "./agent/echoproxy.js";
import * as dxos_agent_epoch from "./agent/epoch.js";
import * as dxos_agent_functions from "./agent/functions.js";
import * as dxos_bot from "./bot.js";
import * as dxos_client_services from "./client/services.js";
import * as dxos_devtools_host from "./devtools/host.js";
import * as dxos_devtools_swarm from "./devtools/swarm.js";
import * as dxos_echo_blob from "./echo/blob.js";
import * as dxos_echo_feed from "./echo/feed.js";
import * as dxos_echo_filter from "./echo/filter.js";
import * as dxos_echo_indexing from "./echo/indexing.js";
import * as dxos_echo_metadata from "./echo/metadata.js";
import * as dxos_echo_model_document from "./echo/model/document.js";
import * as dxos_echo_model_messenger from "./echo/model/messenger.js";
import * as dxos_echo_model_text from "./echo/model/text.js";
import * as dxos_echo_object from "./echo/object.js";
import * as dxos_echo_query from "./echo/query.js";
import * as dxos_echo_service from "./echo/service.js";
import * as dxos_echo_snapshot from "./echo/snapshot.js";
import * as dxos_echo_timeframe from "./echo/timeframe.js";
import * as dxos_edge_messenger from "./edge/messenger.js";
import * as dxos_error from "./error.js";
import * as dxos_gravity from "./gravity.js";
import * as dxos_halo_credentials from "./halo/credentials.js";
import * as dxos_halo_credentials_auth from "./halo/credentials/auth.js";
import * as dxos_halo_credentials_greet from "./halo/credentials/greet.js";
import * as dxos_halo_credentials_identity from "./halo/credentials/identity.js";
import * as dxos_halo_invitations from "./halo/invitations.js";
import * as dxos_halo_keyring from "./halo/keyring.js";
import * as dxos_halo_keys from "./halo/keys.js";
import * as dxos_halo_signed from "./halo/signed.js";
import * as dxos_iframe from "./iframe.js";
import * as dxos_keys from "./keys.js";
import * as dxos_mesh_bridge from "./mesh/bridge.js";
import * as dxos_mesh_broadcast from "./mesh/broadcast.js";
import * as dxos_mesh_messaging from "./mesh/messaging.js";
import * as dxos_mesh_muxer from "./mesh/muxer.js";
import * as dxos_mesh_presence from "./mesh/presence.js";
import * as dxos_mesh_protocol from "./mesh/protocol.js";
import * as dxos_mesh_replicator from "./mesh/replicator.js";
import * as dxos_mesh_signal from "./mesh/signal.js";
import * as dxos_mesh_swarm from "./mesh/swarm.js";
import * as dxos_mesh_teleport from "./mesh/teleport.js";
import * as dxos_mesh_teleport_auth from "./mesh/teleport/auth.js";
import * as dxos_mesh_teleport_automerge from "./mesh/teleport/automerge.js";
import * as dxos_mesh_teleport_blobsync from "./mesh/teleport/blobsync.js";
import * as dxos_mesh_teleport_control from "./mesh/teleport/control.js";
import * as dxos_mesh_teleport_gossip from "./mesh/teleport/gossip.js";
import * as dxos_mesh_teleport_notarization from "./mesh/teleport/notarization.js";
import * as dxos_mesh_teleport_replicator from "./mesh/teleport/replicator.js";
import * as dxos_registry from "./registry.js";
import * as dxos_rpc from "./rpc.js";
import * as dxos_service_agentmanager from "./service/agentmanager.js";
import * as dxos_service_publisher from "./service/publisher.js";
import * as dxos_service_supervisor from "./service/supervisor.js";
import * as dxos_service_tunnel from "./service/tunnel.js";
import * as dxos_tracing from "./tracing.js";
import * as dxos_type from "./type.js";
import * as dxos_value from "./value.js";
import * as example_testing_data from "../example/testing/data.js";
import * as example_testing_rpc from "../example/testing/rpc.js";
import * as google_protobuf from "../google/protobuf.js";
/**
 * Defined in:
 *   {@link file://./../../dxos/config.proto}
 */
export interface Config {
    /**
     * Options:
     *   - proto3_optional = true
     */
    version?: number;
    /**
     * Options:
     *   - proto3_optional = true
     */
    package?: Package;
    /**
     * Options:
     *   - proto3_optional = true
     */
    runtime?: Runtime;
}
/**
 * Defined in:
 *   {@link file://./../../dxos/config.proto}
 */
export interface Package {
    /**
     * Options:
     *   - proto3_optional = true
     */
    license?: string;
    repos?: Repo[];
    modules?: Module[];
}
/**
 * Defined in:
 *   {@link file://./../../dxos/config.proto}
 */
export interface Repo {
    /**
     * Options:
     *   - proto3_optional = true
     */
    name?: string;
    /**
     * Options:
     *   - proto3_optional = true
     */
    url?: string;
    /**
     * Options:
     *   - proto3_optional = true
     */
    version?: string;
}
/**
 * Defined in:
 *   {@link file://./../../dxos/config.proto}
 */
export interface Module {
    /**
     * Options:
     *   - proto3_optional = true
     */
    id?: string;
    /**
     * Options:
     *   - proto3_optional = true
     */
    name?: string;
    /**
     * Options:
     *   - proto3_optional = true
     */
    type?: string;
    /**
     * Options:
     *   - proto3_optional = true
     */
    displayName?: string;
    /**
     * Options:
     *   - proto3_optional = true
     */
    description?: string;
    tags?: string[];
    /**
     * Options:
     *   - proto3_optional = true
     */
    bundle?: Uint8Array;
    /**
     * Options:
     *   - proto3_optional = true
     */
    tunnel?: boolean;
    deps?: Module[];
    /**
     * Options:
     *   - proto3_optional = true
     */
    build?: Module.Build;
    repos?: Repo[];
    /**
     * The data will be validated based on the type when being published.
     *
     * Options:
     *   - proto3_optional = true
     */
    record?: ReturnType<(typeof substitutions)["google.protobuf.Any"]["decode"]>;
}
export namespace Module {
    /**
     * Defined in:
     *   {@link file://./../../dxos/config.proto}
     */
    export interface Build {
        /**
         * Options:
         *   - proto3_optional = true
         */
        command?: string;
        /**
         * Options:
         *   - proto3_optional = true
         */
        outdir?: string;
        /**
         * Options:
         *   - proto3_optional = true
         */
        version?: string;
        /**
         * Options:
         *   - proto3_optional = true
         */
        tag?: string;
        /**
         * Options:
         *   - proto3_optional = true
         */
        env?: ReturnType<(typeof substitutions)["google.protobuf.Struct"]["decode"]>;
    }
}
/**
 * Defined in:
 *   {@link file://./../../dxos/config.proto}
 */
export interface Runtime {
    /**
     * Options:
     *   - proto3_optional = true
     */
    client?: Runtime.Client;
    /**
     * Options:
     *   - proto3_optional = true
     */
    app?: Runtime.App;
    /**
     * Options:
     *   - proto3_optional = true
     */
    cli?: Runtime.CLI;
    /**
     * Options:
     *   - proto3_optional = true
     */
    props?: Runtime.Props;
    /**
     * Options:
     *   - proto3_optional = true
     */
    services?: Runtime.Services;
    /**
     * Options:
     *   - proto3_optional = true
     */
    system?: Runtime.System;
    /**
     * Options:
     *   - proto3_optional = true
     */
    kube?: Runtime.Kube;
    keys?: Runtime.Keys[];
    /**
     * Options:
     *   - proto3_optional = true
     */
    agent?: Runtime.Agent;
}
export namespace Runtime {
    /**
     * Defined in:
     *   {@link file://./../../dxos/config.proto}
     */
    export interface Client {
        /**
         * Options:
         *   - proto3_optional = true
         */
        log?: Runtime.Client.Log;
        /**
         * Options:
         *   - proto3_optional = true
         */
        storage?: Runtime.Client.Storage;
        /**
         * Options:
         *   - proto3_optional = true
         */
        enableSnapshots?: boolean;
        /**
         * Milliseconds
         *
         * Options:
         *   - proto3_optional = true
         */
        snapshotInterval?: number;
        /**
         * Milliseconds
         *
         * Options:
         *   - proto3_optional = true
         */
        invitationExpiration?: number;
        /**
         * Options:
         *   - proto3_optional = true
         */
        remoteSource?: string;
        /**
         * Options:
         *   - proto3_optional = true
         */
        remoteSourceAuthenticationToken?: string;
        /**
         * Connect to and serve client services to a remote proxy.
         *
         * Options:
         *   - proto3_optional = true
         */
        devtoolsProxy?: string;
        /**
         * Spaces will stay in SpaceState.CLOSED until explicitly opened. Speeds up client-services initialization.
         *
         * Options:
         *   - proto3_optional = true
         */
        lazySpaceOpen?: boolean;
        /**
         * Disabled automerge & hypercore replication through P2P connections.
         *
         * Options:
         *   - proto3_optional = true
         */
        disableP2pReplication?: boolean;
        /**
         * Options:
         *   - proto3_optional = true
         */
        edgeFeatures?: Runtime.Client.EdgeFeatures;
    }
    export namespace Client {
        /**
         * Defined in:
         *   {@link file://./../../dxos/config.proto}
         */
        export interface Storage {
            /**
             * Options:
             *   - proto3_optional = true
             */
            persistent?: boolean;
            /**
             * Options:
             *   - proto3_optional = true
             */
            keyStore?: Runtime.Client.Storage.StorageDriver;
            /**
             * Options:
             *   - proto3_optional = true
             */
            dataStore?: Runtime.Client.Storage.StorageDriver;
            /**
             * Options:
             *   - proto3_optional = true
             */
            dataRoot?: string;
            /**
             * @deprecated
             *
             * Options:
             *   - proto3_optional = true
             */
            spaceFragmentation?: boolean;
        }
        export namespace Storage {
            /**
             * Defined in:
             *   {@link file://./../../dxos/config.proto}
             */
            export enum StorageDriver {
                RAM = 0,
                IDB = 1,
                CHROME = 2,
                FIREFOX = 3,
                NODE = 4,
                WEBFS = 5,
                LEVELJS = 11,
                JSONDOWN = 12
            }
        }
        /**
         * Defined in:
         *   {@link file://./../../dxos/config.proto}
         */
        export interface Log {
            /**
             * Options:
             *   - (env_var) = "LOG_FILTER"
             *   - proto3_optional = true
             */
            filter?: string;
            /**
             * Options:
             *   - (env_var) = "LOG_PREFIX"
             *   - proto3_optional = true
             */
            prefix?: string;
        }
        /**
         * Defined in:
         *   {@link file://./../../dxos/config.proto}
         */
        export interface EdgeFeatures {
            /**
             * Options:
             *   - proto3_optional = true
             */
            feedReplicator?: boolean;
            /**
             * Options:
             *   - proto3_optional = true
             */
            echoReplicator?: boolean;
            /**
             * Options:
             *   - proto3_optional = true
             */
            signaling?: boolean;
            /**
             * Options:
             *   - proto3_optional = true
             */
            agents?: boolean;
        }
    }
    /**
     * Defined in:
     *   {@link file://./../../dxos/config.proto}
     */
    export interface App {
        /**
         * Options:
         *   - proto3_optional = true
         */
        org?: string;
        /**
         * Options:
         *   - proto3_optional = true
         */
        theme?: string;
        /**
         * Options:
         *   - proto3_optional = true
         */
        website?: string;
        /**
         * Options:
         *   - (env_var) = "PUBLIC_URL"
         *   - proto3_optional = true
         */
        publicUrl?: string;
        /**
         * Options:
         *   - proto3_optional = true
         */
        build?: Runtime.App.BuildInfo;
        /**
         * Options:
         *   - proto3_optional = true
         */
        env?: ReturnType<(typeof substitutions)["google.protobuf.Struct"]["decode"]>;
    }
    export namespace App {
        /**
         * Defined in:
         *   {@link file://./../../dxos/config.proto}
         */
        export interface BuildInfo {
            /**
             * RFC-3339 datetime string.
             *
             * Options:
             *   - proto3_optional = true
             */
            timestamp?: string;
            /**
             * Options:
             *   - proto3_optional = true
             */
            commitHash?: string;
            /**
             * Version from package.json.
             *
             * Options:
             *   - proto3_optional = true
             */
            version?: string;
            /**
             * Options:
             *   - proto3_optional = true
             */
            branch?: string;
        }
    }
    /**
     * Defined in:
     *   {@link file://./../../dxos/config.proto}
     */
    export interface Agent {
        plugins?: Runtime.Agent.Plugin[];
    }
    export namespace Agent {
        /**
         * Defined in:
         *   {@link file://./../../dxos/config.proto}
         */
        export interface Plugin {
            id: string;
            /**
             * Options:
             *   - proto3_optional = true
             */
            name?: string;
            /**
             * Options:
             *   - proto3_optional = true
             */
            enabled?: boolean;
            /**
             * Options:
             *   - proto3_optional = true
             */
            config?: ReturnType<(typeof substitutions)["google.protobuf.Any"]["decode"]>;
        }
    }
    /**
     * Defined in:
     *   {@link file://./../../dxos/config.proto}
     */
    export interface CLI {
        /**
         * Options:
         *   - proto3_optional = true
         */
        nodePath?: string;
        /**
         * Options:
         *   - proto3_optional = true
         */
        app?: Runtime.CLI.AppServe;
        /**
         * Options:
         *   - proto3_optional = true
         */
        console?: Runtime.CLI.Package;
        /**
         * Options:
         *   - proto3_optional = true
         */
        mdns?: Runtime.CLI.Package;
        /**
         * Options:
         *   - proto3_optional = true
         */
        signal?: Runtime.CLI.Package;
        /**
         * Options:
         *   - proto3_optional = true
         */
        npmClient?: string;
        /**
         * Options:
         *   - proto3_optional = true
         */
        channel?: string;
    }
    export namespace CLI {
        /**
         * Defined in:
         *   {@link file://./../../dxos/config.proto}
         */
        export interface AppServe {
            /**
             * Options:
             *   - proto3_optional = true
             */
            serve?: Runtime.CLI.AppServe.Serve;
        }
        export namespace AppServe {
            /**
             * Defined in:
             *   {@link file://./../../dxos/config.proto}
             */
            export interface Serve {
                /**
                 * Options:
                 *   - proto3_optional = true
                 */
                config?: string;
                /**
                 * Options:
                 *   - proto3_optional = true
                 */
                loginApp?: string;
                /**
                 * Options:
                 *   - proto3_optional = true
                 */
                keyPhrase?: string;
            }
        }
        /**
         * Defined in:
         *   {@link file://./../../dxos/config.proto}
         */
        export interface Package {
            /**
             * Options:
             *   - proto3_optional = true
             */
            package?: string;
            /**
             * Options:
             *   - proto3_optional = true
             */
            channel?: string;
            /**
             * Options:
             *   - proto3_optional = true
             */
            bin?: string;
            /**
             * Options:
             *   - proto3_optional = true
             */
            config?: string;
        }
    }
    /**
     * Defined in:
     *   {@link file://./../../dxos/config.proto}
     */
    export interface Props {
        /**
         * Options:
         *   - proto3_optional = true
         */
        title?: string;
    }
    /**
     * Defined in:
     *   {@link file://./../../dxos/config.proto}
     */
    export interface System {
        /**
         * Options:
         *   - proto3_optional = true
         */
        debug?: string;
    }
    /**
     * Defined in:
     *   {@link file://./../../dxos/config.proto}
     */
    export interface Kube {
        host: string;
        port: string;
        autoupdate: Runtime.Kube.Autoupdate;
        https: Runtime.Kube.Https;
        p2p: Runtime.Kube.P2P;
        confhost: string;
        env?: string[];
        alias?: string[];
        monitoring: Runtime.Kube.Monitoring;
        trace: Runtime.Kube.Trace;
        telemetry: Runtime.Kube.Telemetry;
        gravity: Runtime.Kube.Gravity;
        appTunneling: Runtime.Kube.AppTunneling;
        bots: Runtime.Kube.Bots;
        ipfsApiAuthorizations?: Runtime.Kube.KuboApiAuthorization[];
    }
    export namespace Kube {
        /**
         * Defined in:
         *   {@link file://./../../dxos/config.proto}
         */
        export interface Autoupdate {
            enabled: boolean;
            /**
             * Seconds
             */
            interval: number;
        }
        /**
         * Defined in:
         *   {@link file://./../../dxos/config.proto}
         */
        export interface Https {
            enabled: boolean;
            port: string;
            email: string;
            certfile: string;
            keyfile: string;
            dnsprovider: string;
        }
        /**
         * Defined in:
         *   {@link file://./../../dxos/config.proto}
         */
        export interface P2P {
            privatekey: string;
            port: string;
            bootstrap?: string[];
        }
        /**
         * Defined in:
         *   {@link file://./../../dxos/config.proto}
         */
        export interface Monitoring {
            enabled: boolean;
            endpoint: string;
            /**
             * Seconds
             */
            interval: number;
        }
        /**
         * Defined in:
         *   {@link file://./../../dxos/config.proto}
         */
        export interface Trace {
            disabled: boolean;
        }
        /**
         * Defined in:
         *   {@link file://./../../dxos/config.proto}
         */
        export interface Telemetry {
            disabled: boolean;
            tags?: string[];
        }
        /**
         * Defined in:
         *   {@link file://./../../dxos/config.proto}
         */
        export interface Gravity {
            disabled: boolean;
            name: string;
            /**
             * Options:
             *   - proto3_optional = true
             */
            logDir?: string;
        }
        /**
         * Defined in:
         *   {@link file://./../../dxos/config.proto}
         */
        export interface AppTunneling {
            enabled: boolean;
            commandTemplate: string;
            outputRe: string;
            port: string;
        }
        /**
         * Defined in:
         *   {@link file://./../../dxos/config.proto}
         */
        export interface Bots {
            enabled: boolean;
            /**
             * Refresh interval - Seconds
             */
            interval: number;
        }
        /**
         * https://github.com/ipfs/kubo/blob/master/docs/config.md#apiauthorizations
         *
         * Defined in:
         *   {@link file://./../../dxos/config.proto}
         */
        export interface KuboApiAuthorization {
            /**
             * Just for organizational purposes.
             */
            name: string;
            authSecret: string;
            allowedPaths?: string[];
        }
    }
    /**
     * Defined in:
     *   {@link file://./../../dxos/config.proto}
     */
    export interface Services {
        /**
         * Options:
         *   - proto3_optional = true
         */
        kube?: Runtime.Services.Kube;
        /**
         * Options:
         *   - proto3_optional = true
         */
        app?: Runtime.Services.AppServer;
        /**
         * Options:
         *   - proto3_optional = true
         */
        dxns?: Runtime.Services.Dxns;
        /**
         * Options:
         *   - proto3_optional = true
         */
        ipfs?: Runtime.Services.Ipfs;
        signaling?: Runtime.Services.Signal[];
        ice?: Runtime.Services.Ice[];
        iceProviders?: Runtime.Services.IceProvider[];
        /**
         * Options:
         *   - proto3_optional = true
         */
        machine?: Runtime.Services.Machine;
        /**
         * Options:
         *   - proto3_optional = true
         */
        bot?: Runtime.Services.BotHost;
        /**
         * Options:
         *   - proto3_optional = true
         */
        publisher?: Runtime.Services.Publisher;
        /**
         * Options:
         *   - proto3_optional = true
         */
        supervisor?: Runtime.Services.Supervisor;
        /**
         * Options:
         *   - proto3_optional = true
         */
        tunneling?: Runtime.Services.Tunneling;
        /**
         * Options:
         *   - proto3_optional = true
         */
        faasd?: Runtime.Services.Faasd;
        /**
         * Options:
         *   - proto3_optional = true
         */
        agentHosting?: Runtime.Services.AgentHosting;
        /**
         * Options:
         *   - proto3_optional = true
         */
        edge?: Runtime.Services.Edge;
        /**
         * Options:
         *   - proto3_optional = true
         */
        ai?: Runtime.Services.Ai;
    }
    export namespace Services {
        /**
         * Defined in:
         *   {@link file://./../../dxos/config.proto}
         */
        export interface Kube {
            /**
             * Options:
             *   - proto3_optional = true
             */
            endpoints?: Runtime.Services.Kube.Endpoints;
            /**
             * Options:
             *   - proto3_optional = true
             */
            publicUrl?: string;
        }
        export namespace Kube {
            /**
             * Defined in:
             *   {@link file://./../../dxos/config.proto}
             */
            export interface Endpoints {
                /**
                 * Options:
                 *   - proto3_optional = true
                 */
                logs?: string;
                /**
                 * Options:
                 *   - proto3_optional = true
                 */
                services?: string;
                /**
                 * Options:
                 *   - proto3_optional = true
                 */
                cert?: string;
            }
        }
        /**
         * Defined in:
         *   {@link file://./../../dxos/config.proto}
         */
        export interface AppServer {
            /**
             * Options:
             *   - proto3_optional = true
             */
            prefix?: string;
            /**
             * Options:
             *   - proto3_optional = true
             */
            server?: string;
        }
        /**
         * Defined in:
         *   {@link file://./../../dxos/config.proto}
         */
        export interface Dxns {
            /**
             * DXNS endpoint.
             *
             * Options:
             *   - proto3_optional = true
             */
            server?: string;
            /**
             * Substrate account URI. This is a secret.
             * KUBEs do not serve this with the config but we store it in profile.yml.
             *
             * TODO(dmaretskyi): Deprecate this and move it to keyring.
             *
             * Options:
             *   - proto3_optional = true
             */
            accountUri?: string;
            /**
             * Public Polkadot Address.
             *
             * Options:
             *   - proto3_optional = true
             */
            address?: string;
            /**
             * Public address of a DXNS Account.
             *
             * Options:
             *   - proto3_optional = true
             */
            account?: string;
            /**
             * Options:
             *   - proto3_optional = true
             */
            faucet?: string;
        }
        /**
         * Defined in:
         *   {@link file://./../../dxos/config.proto}
         */
        export interface Ipfs {
            /**
             * Options:
             *   - proto3_optional = true
             */
            server?: string;
            /**
             * Options:
             *   - proto3_optional = true
             */
            gateway?: string;
            /**
             * Same secret format as https://github.com/ipfs/kubo/blob/master/docs/config.md#apiauthorizations
             *
             * Options:
             *   - proto3_optional = true
             */
            serverAuthSecret?: string;
        }
        /**
         * Defined in:
         *   {@link file://./../../dxos/config.proto}
         */
        export interface Signal {
            server: string;
            /**
             * Options:
             *   - proto3_optional = true
             */
            api?: string;
            /**
             * Options:
             *   - proto3_optional = true
             */
            status?: string;
        }
        /**
         * Defined in:
         *   {@link file://./../../dxos/config.proto}
         */
        export interface Ice {
            urls: string;
            /**
             * Options:
             *   - proto3_optional = true
             */
            username?: string;
            /**
             * Options:
             *   - proto3_optional = true
             */
            credential?: string;
        }
        /**
         * Defined in:
         *   {@link file://./../../dxos/config.proto}
         */
        export interface IceProvider {
            urls: string;
        }
        /**
         * Defined in:
         *   {@link file://./../../dxos/config.proto}
         */
        export interface Machine {
            /**
             * Options:
             *   - proto3_optional = true
             */
            doAccessToken?: string;
            /**
             * Options:
             *   - proto3_optional = true
             */
            githubAccessToken?: string;
            /**
             * Options:
             *   - proto3_optional = true
             */
            githubUsername?: string;
            /**
             * Options:
             *   - proto3_optional = true
             */
            dnsDomain?: string;
            /**
             * Options:
             *   - proto3_optional = true
             */
            npmAccessToken?: string;
        }
        /**
         * Defined in:
         *   {@link file://./../../dxos/config.proto}
         */
        export interface BotHost {
            /**
             * Options:
             *   - proto3_optional = true
             */
            proxy?: string;
        }
        /**
         * Defined in:
         *   {@link file://./../../dxos/config.proto}
         */
        export interface Publisher {
            /**
             * Options:
             *   - proto3_optional = true
             */
            server?: string;
        }
        /**
         * Defined in:
         *   {@link file://./../../dxos/config.proto}
         */
        export interface Tunneling {
            /**
             * Options:
             *   - proto3_optional = true
             */
            server?: string;
        }
        /**
         * Defined in:
         *   {@link file://./../../dxos/config.proto}
         */
        export interface Supervisor {
            /**
             * Options:
             *   - proto3_optional = true
             */
            server?: string;
        }
        /**
         * Defined in:
         *   {@link file://./../../dxos/config.proto}
         */
        export interface Faasd {
            /**
             * Options:
             *   - proto3_optional = true
             */
            gateway?: string;
            /**
             * Options:
             *   - proto3_optional = true
             */
            username?: string;
            /**
             * Options:
             *   - proto3_optional = true
             */
            password?: string;
        }
        /**
         * Defined in:
         *   {@link file://./../../dxos/config.proto}
         */
        export interface AgentHosting {
            /**
             * Options:
             *   - proto3_optional = true
             */
            server?: string;
            /**
             * Options:
             *   - proto3_optional = true
             */
            type?: string;
        }
        /**
         * Defined in:
         *   {@link file://./../../dxos/config.proto}
         */
        export interface Edge {
            /**
             * Options:
             *   - proto3_optional = true
             */
            url?: string;
        }
        /**
         * Defined in:
         *   {@link file://./../../dxos/config.proto}
         */
        export interface Ai {
            /**
             * Options:
             *   - proto3_optional = true
             */
            server?: string;
        }
    }
    /**
     * Defined in:
     *   {@link file://./../../dxos/config.proto}
     */
    export interface Keys {
        /**
         * Options:
         *   - proto3_optional = true
         */
        name?: string;
        /**
         * Options:
         *   - proto3_optional = true
         */
        value?: string;
    }
}
